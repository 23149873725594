import React, {Fragment} from 'react';
import {DialogWrapper, Button, Title} from './DialogWidgets';
import * as Ant from 'antd';

let WECHAT_ID = {
  台北: 'vbeauty9663',
  台中: 'vbeautytc',
};

class QrCodeDialog extends React.Component {
  render() {
    let {closeDialog, show, location} = this.props;

    return (
      <Ant.Modal
        visible={show}
        title={<Title>{`微媞時尚診所${location}院WeChat`}</Title>}
        footer={
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}>
            <Button css="margin: 10px;" onClick={() => closeDialog()}>
              確定
            </Button>
          </div>
        }
        closeIcon={<p>X</p>}
        onCancel={closeDialog}
        width={350}>
        <DialogWrapper>
          <div
            className="content"
            style={{
              textAlign: 'center',
              whiteSpace: 'pre-wrap',
              paddingTop: 5,
            }}>
            <img
              src={`../../images/wechat-${location}.jpg`}
              style={{maxWidth: 200, objectFit: 'contain'}}
            />
            <p style={{marginTop: 20, fontWeight: 'bold'}}>
              WeChat ID: {WECHAT_ID[location]}
            </p>
          </div>
        </DialogWrapper>
      </Ant.Modal>
    );
  }
}

export default QrCodeDialog;
