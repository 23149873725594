import React from 'react';
import styled from 'styled-components';
import {Button, Form, Input} from 'antd';
import {navigate, Link} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';
import {useOutlet, useOutletSetter} from 'reconnect.js';
import useBreakpoint from '../../Hooks/useBreakPoint';
import Carousel from '../../Components/CarouselWrapper';
import Faqs from './faqCollapse';
import MailForm from './MailForm';
import QrCodeDialog from '../../Components/QrCodeDialog';

function About(props) {
  const [actions] = useOutlet('actions');
  const [dimension] = useOutlet('dimension');
  const [articles, setArticles] = React.useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [location, setLocation] = React.useState(false);
  const [banner, setBanner] = React.useState('');

  const [bannerDimension, setBannerDimension] = React.useState(null);

  React.useLayoutEffect(() => {
    const elem = document.getElementById('rev-banner');
    const box = elem.getBoundingClientRect();
    setBannerDimension(box);
  }, [dimension.innerWidth]);

  React.useEffect(() => {
    async function fetchArticleData() {
      try {
        actions.setLoading(true);
        let resp = await actions.fetchDocuments('Article_Default');
        let dataList = resp.results;
        setArticles(dataList);
      } catch (ex) {
        console.warn(ex);
      } finally {
        actions.setLoading(false);
      }
    }
    async function fetchBanner() {
      actions.setLoading(true);
      let bannerList = await actions.fetchDocuments('banner');
      bannerList = bannerList.results;
      let _banner = await bannerList.filter(
        (banner) => banner.type === 'contact',
      )[0];
      actions.setLoading(false);
      setBanner(_banner.image);
    }
    fetchBanner();

    fetchArticleData();
  }, [actions]);

  return (
    <Wrapper isMobile={dimension.rwd !== 'desktop'}>
      <div
        id="rev-banner"
        className="banner"
        style={{height: bannerDimension?.width * 0.38}}>
        <img
          src={banner}
          style={{width: '100%', height: '100%', objectFit: 'cover'}}
        />
      </div>

      <section className={`light-bkg`}>
        <div
          className={`constraint ${dimension.rwd === 'desktop' ? 'row' : ''}`}
          style={{alignItems: 'flex-end'}}>
          <div
            style={{
              flexBasis: dimension.innerWidth / 2.5,
              paddingRight: dimension.rwd === 'desktop' ? 40 : 0,
            }}>
            <div className="subtitle">CONTACT US</div>
            <div className="title">聯絡我們</div>
            <p
              className="content"
              style={{
                marginBottom: 20,
                marginTop: 25,
              }}>{`歡迎透過表單留言諮詢，我們會盡快與您聯繫。`}</p>
            <MailForm />
          </div>
          <div style={{display: 'flex', flexBasis: dimension.innerWidth / 2.5}}>
            <img
              src={'../../images/contact-1.jpg'}
              style={{
                width: '100%',
                height: dimension.rwd === 'desktop' ? 1240 / 2.5 : 'auto',
                objectFit: 'cover',
              }}
            />
          </div>
        </div>
      </section>

      <section className="section">
        <div className="subtitle">FAQ</div>
        <div className="title">常見問答</div>
        <p
          className="content"
          style={{
            marginTop: 25,
          }}>{`若對於服務項目有其他需求及疑問，歡迎透過線上客服或填寫意見表單與我們聯繫，我們會盡快為您服務。`}</p>
        <Faqs />
      </section>
      <section className="section">
        <div className="subtitle">FOLLOW US</div>
        <div className="title">加入我們</div>
        <p
          className="content"
          style={{
            marginTop: 25,
          }}>{`歡迎追蹤微媞時尚診所相關官方社群，持續關注微媞最新動態，與美麗更靠近。`}</p>
        <div className="line" style={{marginTop: 30, marginBottom: 50}} />
        <div
          className={`${dimension.rwd === 'desktop' && 'row'}`}
          style={{alignItems: 'flex-end'}}>
          <div>
            <div className="title primary-color">微媞時尚診所 台北院</div>
            <div
              className="content"
              style={{
                paddingTop: 20,
              }}>{`服務專線 (02) 2721-9663
診所位置 106台北市大安區忠孝東路四段250號2樓(忠孝敦化站3號出口)
營業時間 週一到五 12:00至21:00 週六11:00至20:00(周日公休)`}</div>
          </div>
          <div
            className="row"
            style={{marginTop: 14, flex: 1, justifyContent: 'center'}}>
            <a
              className="social-item"
              style={{marginLeft: 20}}
              href={'https://line.me/R/ti/p/@vbeauty-tpe'}
              target="_blank"
              rel="noreferrer">
              <img
                src="../../images/line.png"
                style={{width: '60%', maxHeight: 60, objectFit: 'contain'}}
              />
              <p className="contact-title">{`台北院
Line生活圈`}</p>
              {/* <p>@vbeauty-tpe</p> */}
            </a>

            <a
              className="social-item"
              href={'https://m.me/vbclinic'}
              target="_blank"
              rel="noreferrer">
              <img
                src="../../images/messenger.png"
                style={{width: '60%', maxHeight: 60, objectFit: 'contain'}}
              />
              <p className="contact-title">{`台北院
Messenger`}</p>
              {/* <p>@vbeauty-tc</p> */}
            </a>

            <a
              className="social-item"
              onClick={() => {
                setLocation('台北');
                setOpenDialog(true);
              }}
              // href={'https://m.me/vbclinic'}
              // target="_blank"
              // rel="noreferrer"
            >
              <img
                src="../../images/wechat.png"
                style={{width: '60%', maxHeight: 60, objectFit: 'contain'}}
              />
              <p className="contact-title">{`台北院
Wechat微信`}</p>
              {/* <p>vbeauty9663</p> */}
            </a>
          </div>

          {/* <div className="social-item">
            <img
              src="../../images/ig.png"
              style={{width: '60%', maxHeight: 60, objectFit: 'contain'}}
            />
            <p className="title">台北院instagram</p>
          </div>

          <div className="social-item">
            <img
              src="../../images/youtube.png"
              style={{width: '60%', maxHeight: 60, objectFit: 'contain'}}
            />
            <p className="title">台北院youtube頻道</p>
          </div> */}
        </div>
        <div style={{flex: 1, backgroundColor: '#eee', marginTop: 30}}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.7925115794037!2d121.55197895052211!3d25.041114583890245!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442abc670875589%3A0x1780385130482511!2z5b6u5aqe5pmC5bCa6Ki65omA!5e0!3m2!1szh-TW!2stw!4v1626772948563!5m2!1szh-TW!2stw"
            width={'100%'}
            height="400"
            style={{border: 0}}
            allowFullScreen={true}
            loading="lazy"></iframe>
        </div>
        <div className="line" style={{marginTop: 30, marginBottom: 50}} />
        <div
          className={`${dimension.rwd === 'desktop' && 'row'}`}
          style={{alignItems: 'flex-end'}}>
          <div>
            <div className="title primary-color">微媞時尚診所 台中院</div>
            <div
              className="content"
              style={{
                paddingTop: 20,
              }}>{`服務專線 (04)2328-8880
診所位置 408台中市南屯區向上路一段658號3樓(文心森林公園站)
營業時間 週一到四 12:00至21:00 週五六11:00至20:00(周日公休)`}</div>
          </div>
          <div
            className="row"
            style={{marginTop: 14, flex: 1, justifyContent: 'center'}}>
            <a
              className="social-item"
              style={{marginLeft: 20}}
              href={'https://line.me/R/ti/p/@vbeauty-tc'}
              target="_blank"
              rel="noreferrer">
              <img
                src="../../images/line.png"
                style={{width: '60%', maxHeight: 60, objectFit: 'contain'}}
              />
              <p className="contact-title">{`台中院
Line生活圈`}</p>
              {/* <p>@vbeauty-tpe</p> */}
            </a>

            <a
              className="social-item"
              href={'https://m.me/vbeautyTC'}
              target="_blank"
              rel="noreferrer">
              <img
                src="../../images/messenger.png"
                style={{width: '60%', maxHeight: 60, objectFit: 'contain'}}
              />
              <p className="contact-title">{`台中院
Messenger`}</p>
              {/* <p>@vbeauty-tc</p> */}
            </a>

            <a
              className="social-item"
              onClick={() => {
                setLocation('台中');
                setOpenDialog(true);
              }}>
              <img
                src="../../images/wechat.png"
                style={{width: '60%', maxHeight: 60, objectFit: 'contain'}}
              />
              <p className="contact-title">{`台中院
Wechat微信`}</p>
              {/* <p>vbeauty9663</p> */}
            </a>
            <QrCodeDialog
              location={location}
              show={openDialog}
              closeDialog={() => setOpenDialog(false)}
            />
          </div>
        </div>
        <div style={{flex: 1, backgroundColor: '#eee', marginTop: 30}}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3640.70681911426!2d120.6461666505043!3d24.146932184318736!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34693dbe7bb8fcf5%3A0xcab4a1e08b622068!2z5b6u5aqe5pmC5bCa6Ki65omALeWPsOS4reW6lw!5e0!3m2!1szh-TW!2stw!4v1626773290377!5m2!1szh-TW!2stw"
            width={'100%'}
            height="400"
            style={{border: 0}}
            allowFullScreen={true}
            loading="lazy"></iframe>
        </div>
      </section>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .banner {
    width: 100%;
    height: 600px;
    /* position: relative; */
    /* background-color: var(--primaryColor); */
  }

  .social-item {
    /* flex: 1; */
    text-align: center;
    cursor: pointer;
    margin: ${(props) => (props.isMobile ? '0px 5px' : '0px 30px')};
    & img {
      min-width: 80px;
    }
    & > p {
      font-size: 13px;
      letter-spacing: 1.86px;
      font-weight: 300;
    }
    .contact-title {
      font-weight: 500;
      margin-top: 10px;
      color: var(--primaryFontColor);
      white-space: break-spaces;
    }
  }
`;

export default About;
