import React from 'react';
import styled from 'styled-components';
import {Button, Form, Input, Collapse} from 'antd';
import {useOutlet} from 'reconnect.js';
const {Panel} = Collapse;

function callback(key) {
  console.log(key);
}

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

const content = [
  {
    question: `請問想安排諮詢看診或進行療程需要先預約嗎？`,
    ans: `為避免現場久候，建議可提前預約，以維護良好服務品質。`,
  },
  {
    question: `請問諮詢看診需要掛號或收取掛號費嗎？`,
    ans: `不需要，請透過官方客服或來電門市完成預約即可。`,
  },
  {
    question: `若諮詢完，想要直接進行療程可以嗎？`,
    ans: `除手術需另外安排治療時間外，其餘雷射，美容，微整等項目基本上當天諮詢完即可施作，但仍需視當日預約狀況而定，若該時段預約已滿，會盡快為您安排其他治療時間。`,
  },
  {
    question: `我是男生，也可以進行醫美治療嗎？`,
    ans: `當然可以，不管是膚質、五官、除毛保養都是不分男女的，歡迎男士們一起加入愛美的行列。`,
  },
  {
    question: `懷孕期間，能否進行醫美治療？`,
    ans: `懷孕期間不建議進行任何治療，避免影響宮縮或胎兒發展，建議可以等待產後哺乳結束，再進行諮詢。`,
  },
  {
    question: `哺乳或坐月子期間，能否進行醫美治療？`,
    ans: `哺乳或坐月子期間可以接受非侵入性的醫美療程，其他治療項目建議可與專業醫師溝通評估後確定是否能夠進行。`,
  },
  {
    question: `未滿18歲，能否進行醫美治療嗎？`,
    ans: `衛福部規定，未滿18歲者不得接受手術類、微整形及雷射光療類等侵入性醫學美容療程。`,
  },
  {
    question: `18以上歲未滿20歲者，能否進行醫美治療？`,
    ans: `衛福部規定，18至20歲者若須接受醫學美容等相關療程，須由法定代理人陪同及簽署同意書，並出示法定代理人之身分證核對。`,
  },
];

function FaqCollapse() {
  const [dimension] = useOutlet('dimension');

  return (
    <Collapse
      defaultActiveKey={['1']}
      onChange={callback}
      expandIconPosition="right"
      ghost>
      {content.map((item, idx) => (
        <ArticleRow
          dimension={dimension}
          header={
            <div className="row" style={{alignItems: 'center'}}>
              <div className="id">{`${idx + 1}.  `} </div>
              <div className="faq-title">{item.question}</div>
            </div>
          }
          key={idx}>
          <p className="faq-content">{item.ans}</p>
        </ArticleRow>
      ))}
    </Collapse>
  );
}

const ArticleRow = styled(Collapse.Panel)`
  background-color: #fff;
  /* padding: ${(props) => (props.isMobile ? '0px' : '12px')}; */
  padding: 10px;
  margin-top: 20px;
  border-radius: 8px;

  /* & .ant-collapse-content-box {
    padding: 0;
  } */

  & .faq-title {
    font-size: ${(props) =>
      props.dimension.rwd === 'desktop' ? '20px' : '16px'};
    letter-spacing: 2.57px;
    font-weight: 500;
    color: var(--contentTextColor);
  }

  & .faq-content {
    /* font-weight: 300; */
    letter-spacing: 2.57px;
    margin-left: 28px;
    padding-top: 15px;
    border-top: 1px solid #ccc;
    font-size: 16px;
    color: #777a76;
  }

  & .id {
    margin-right: 5px;
    color: var(--primaryColor);
    font-weight: bold;
    white-space: pre;
  }
`;

export default FaqCollapse;
